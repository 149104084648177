<template>
  <section
    class="promo--detail-action"
    v-if="banner.promo_code || banner.external_url || banner.web_url"
  >
    <div v-if="banner.promo_code">
      <div class="top--part">
        <span>{{ $t('general.promo.code') }}</span>
        <span class="promo--code">{{ banner.promo_code }}</span>
      </div>
      <button v-if="!copied" class="btn btn-primary" @click="copyCode()">
        {{ $t('general.promo.copy') }}
      </button>
      <button v-else class="btn btn--green" @click="copyCode()">
        <i class="ion-checkmark mr-2"></i>
        <span>{{ $t('general.promo.copied') }}</span>
      </button>
    </div>
    <a
      v-if="banner.external_url"
      class="btn btn-primary mt-3"
      :href="
        banner.external_url.includes('http://') || banner.external_url.includes('https://')
          ? banner.external_url
          : `//${banner.external_url}`
      "
    >
      {{ banner.button_name ? banner.button_name : $t('banner.getBanner') }}
    </a>
    <router-link v-else-if="banner.web_url" class="btn btn-primary mt-3" :to="banner.web_url">
      {{ banner.button_name ? banner.button_name : $t('banner.getBanner') }}
    </router-link>
  </section>
</template>

<script>
export default {
  name: 'promo-action',
  props: ['banner'],
  data() {
    return {
      copied: false,
    };
  },
  methods: {
    copyCode() {
      this.$copyText(this.banner.promo_code);
      this.copied = true;
    },
  },
};
</script>
