<template>
  <section class="promo--detail-content">
    <div class="desc mb-4" v-show="banner.description" v-html="banner.description"></div>
    <div class="desc" v-show="banner.terms_conditions" v-html="banner.terms_conditions"></div>
  </section>
</template>

<script>
export default {
  name: 'promo-content',
  props: ['banner'],
};
</script>
