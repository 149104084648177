<template>
  <default-layout>
    <div class="container promo--detail-container" v-if="banner">
      <banner :banner="banner" />
      <banner-header :banner="banner" />
      <div class="promo--detail-bottom">
        <div class="left--col">
          <banner-content :banner="banner" />
        </div>
        <div class="right--col">
          <action :banner="banner" />
        </div>
      </div>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import Banner from '@/components/banner/banner-detail';
import BannerHeader from '@/components/banner/header';
import BannerContent from '@/components/banner/content';
import Action from '@/components/banner/action';
import { mapState } from 'vuex';

export default {
  name: 'banner-detail',
  components: {
    DefaultLayout,
    Banner,
    BannerHeader,
    BannerContent,
    Action,
  },
  async fetch({ store, route }) {
    await store.dispatch('banner/getBannerDetail', route.params.uuid);
    const promo = store.state.banner.bannerDetail;
    const baseUrl = store.state.global.baseUrl;
    let title = promo.title;
    if (title) {
      // if (title.length > 44) title = title.substring(0, 41) + '...';
      title += ' | BeliRumah';
    }

    let description = promo.description;
    if (description) {
      if (description.length > 295) description = description.substring(0, 295) + '...';
      description += ' | Rentfix.com';
    }

    const head = {
      title: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description,
        },
        {
          hid: 'og-description',
          name: 'og:description',
          content: description,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: baseUrl + route.fullPath,
          id: 'canonical',
        },
      ],
    };
    store.commit('head/SET_CONTENT', head);
  },

  computed: {
    ...mapState({
      banner: (state) => state.banner.bannerDetail,
    }),
  },
  methods: {},
  mounted() {},
};
</script>
