<template>
  <section class="promo--detail-header">
    <div>
      <div class="title--text">
        {{ banner.title }}
      </div>
      <!--      <div class="period" v-show="period">{{ $t('general.promo.period') }}: {{ period }}</div>-->
    </div>
    <!--    <share :url="''"></share>-->
  </section>
</template>

<script>
// import Share from '@/components/utils/share';
export default {
  props: ['banner'],
  components: {
    // Share,
  },
  computed: {
    period() {
      if (this.banner.end_date_time === null) return null;
      return `${this.$date.format(
        this.banner.start_date_time,
        'dd MMM yyyy',
      )} - ${this.$date.format(this.banner.end_date_time, 'dd MMM yyyy')}`;
    },
  },
};
</script>
